// Dependencies
import React from 'react'
import PropTypes from 'prop-types'

// Styles
import { MobileFrame } from '../Work/Work.styled'

const Mobile = ({ children }) => {
  return (
    <MobileFrame>
      <header>
        <svg>
          <circle />
        </svg>
      </header>
      <div>{children}</div>
      <footer>
        <svg>
          <circle />
        </svg>
      </footer>
    </MobileFrame>
  )
}

// Components PropTypes
Mobile.propTypes = {
  children: PropTypes.element.isRequired,
}

export default Mobile
