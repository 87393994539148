// Dependencies
import React from 'react'
import PropTypes from 'prop-types'

// Styles
import { BrowserFrame } from '../Work/Work.styled'

const Browser = ({ children, half }) => {
  return (
    <BrowserFrame half={half}>
      <header>
        <svg>
          <circle />
        </svg>
        <svg>
          <circle />
        </svg>
        <svg>
          <circle />
        </svg>
      </header>
      <div>{children}</div>
      <footer />
    </BrowserFrame>
  )
}

// Components PropTypes
Browser.propTypes = {
  children: PropTypes.element.isRequired,
  mobile: PropTypes.bool,
  half: PropTypes.bool,
}
Browser.defaultProps = {
  mobile: false,
  half: false,
}

export default Browser
