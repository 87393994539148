// Dependencies
import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'

// Components
import Browser from './Browser'
import Mobile from './Mobile'

// Styles
import { Screens, ScreenContainer, Column, Row } from '../Work/Work.styled'

const getScreenContainer = (arrayOfScreens, i) => {
  const isMobile = arrayOfScreens[0].mobile
  const isHalf = arrayOfScreens[0].half

  if (!isHalf && !isMobile) {
    return (
      <ScreenContainer key={'ScreenContainer_' + i}>
        {arrayOfScreens.map((image, index) => {
          return (
            <Browser key={'currentProject_screen_fullwidth_' + i + '_' + index}>
              <Img
                fluid={image.screen.fluid}
                imgStyle={{
                  objectFit: 'contain',
                }}
              />
            </Browser>
          )
        })}
      </ScreenContainer>
    )
  }

  const row = arrayOfScreens.map((image, index) => {
    return isMobile ? (
      <Mobile key={'currentProject_screen_mobile_row_' + i + '_' + index}>
        <Img
          fluid={image.screen.fluid}
          imgStyle={{
            objectFit: 'contain',
          }}
        />
      </Mobile>
    ) : (
      <Browser key={'currentProject_screen_half_row_' + i + '_' + index}>
        <Img
          fluid={image.screen.fluid}
          imgStyle={{
            objectFit: 'contain',
          }}
        />
      </Browser>
    )
  })

  const column1 = arrayOfScreens
    .filter((i, id) => id % 2 === 0)
    .map((image, index) => {
      return isMobile ? (
        <Mobile key={'currentProject_screen_mobile_column1_' + i + '_' + index}>
          <Img
            fluid={image.screen.fluid}
            imgStyle={{
              objectFit: 'contain',
            }}
          />
        </Mobile>
      ) : (
        <Browser key={'currentProject_screen_half_column1_' + i + '_' + index}>
          <Img
            fluid={image.screen.fluid}
            imgStyle={{
              objectFit: 'contain',
            }}
          />
        </Browser>
      )
    })

  const column2 = arrayOfScreens
    .filter((i, id) => id % 2 !== 0)
    .map((image, index) => {
      return isMobile ? (
        <Mobile key={'currentProject_screen_mobile_column2_' + i + '_' + index}>
          <Img
            fluid={image.screen.fluid}
            imgStyle={{
              objectFit: 'contain',
            }}
          />
        </Mobile>
      ) : (
        <Browser key={'currentProject_screen_half_column2_' + i + '_' + index}>
          <Img
            fluid={image.screen.fluid}
            imgStyle={{
              objectFit: 'contain',
            }}
          />
        </Browser>
      )
    })

  return (
    <ScreenContainer mobile={isMobile} key={'ScreenContainer_' + i}>
      <Column>{column1}</Column>
      <Column>{column2}</Column>
      <Row>{row}</Row>
    </ScreenContainer>
  )
}

const ProjectScreens = ({ screens }) => {
  const organizedScreens = screens.reduce((a, b) => {
    if (!a.length) {
      a.push([b])
      return a
    }

    const lastItem = a[a.length - 1]

    if (!b.half && !b.mobile) {
      a.push([b])
      return a
    }

    if (b.mobile) {
      if (!lastItem[lastItem.length - 1].mobile) {
        a.push([b])
      } else if (lastItem[lastItem.length - 1].mobile) {
        lastItem.push(b)
      }
      return a
    }

    if (b.half) {
      if (!lastItem[lastItem.length - 1].half) {
        a.push([b])
      } else if (lastItem[lastItem.length - 1].half) {
        lastItem.push(b)
      }
      return a
    }

    return a
  }, [])

  return (
    organizedScreens && (
      <Screens>
        {organizedScreens.map((arrayOfScreens, index) => {
          return arrayOfScreens
            ? getScreenContainer(arrayOfScreens, index)
            : null
        })}
      </Screens>
    )
  )
}

// Components PropTypes
ProjectScreens.propTypes = {
  screens: PropTypes.arrayOf(
    PropTypes.shape({
      half: PropTypes.bool,
      mobile: PropTypes.bool,
      screen: PropTypes.shape({
        fluid: PropTypes.shape({
          src: PropTypes.string,
          srcSet: PropTypes.string,
          srcWebp: PropTypes.string,
          srcSetWebp: PropTypes.string,
          sizes: PropTypes.string,
          aspectRatio: PropTypes.number,
          base64: PropTypes.string,
        }),
      }),
    })
  ),
}
ProjectScreens.defaultProps = {
  screens: [],
}

export default ProjectScreens
