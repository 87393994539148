// Dependencies
import React from 'react'
import PropTypes from 'prop-types'

// Components
import Reveal from '../Animation/Reveal'

// Styles
import { Involvement } from '../Work/Work.styled'
import FadeIn from '../Animation/FadeIn'

const ProjectInvolvement = ({ title, tags = [] }) => {
  return (
    <Involvement>
      <div>
        <Reveal>
          <h4>{title}</h4>
        </Reveal>
      </div>
      <br />
      <div>
        {tags && (
          <ul>
            {tags.map((tag, index) => {
              return (
                <li key={'project_tag' + index}>
                  <FadeIn>
                    <span>{tag}</span>
                  </FadeIn>
                </li>
              )
            })}
          </ul>
        )}
      </div>
    </Involvement>
  )
}

// Components PropTypes
ProjectInvolvement.propTypes = {
  title: PropTypes.string.isRequired,
  tags: PropTypes.arrayOf(PropTypes.string),
}
ProjectInvolvement.defaultProps = {
  tags: [],
}

export default ProjectInvolvement
