// Dependencies
import React from 'react'
import PropTypes from 'prop-types'

// Components
import Reveal from '../Animation/Reveal'

// Styles
import { Description } from '../Work/Work.styled'

const ProjectDescription = ({ title, text }) => {
  return (
    <Description>
      <div>
        <Reveal>
          <h3>{title}</h3>
        </Reveal>
      </div>
      <br />
      <div>
        <Reveal>
          {text &&
            text.split('\n').map((p, i) => {
              return (
                <p
                  key={'project_description_p' + i}
                  dangerouslySetInnerHTML={{ __html: p }}
                />
              )
            })}
        </Reveal>
      </div>
    </Description>
  )
}

// Components PropTypes
ProjectDescription.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
}
ProjectDescription.defaultProps = {
  text: '',
}

export default ProjectDescription
