// Dependencies
import React from 'react'
import Img from 'gatsby-image'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Helmet from '../components/Helmet'
import { Parallax } from 'react-parallax'

// Components
import ScaleDown from '../components/Animation/ScaleDown'
import FadeIn from '../components/Animation/FadeIn'
import Reveal from '../components/Animation/Reveal'
import Header from '../components/Header/InternalHeader'
import ProjectsList from '../components/Work/ProjectsList'
import Layout from '../components/layout'

// Styles
import {
  ProjectHeader,
  ProjectHeaderContent,
  ProjectItem,
  ProjectContent,
} from '../components/Work/Work.styled'
import {
  Section,
  SectionTitle,
  CtaLink as Cta,
} from '../components/common/Section.styled'
import ProjectDescription from '../components/Work/ProjectDescription'
import ProjectInvolvement from '../components/Work/ProjectInvolvement'
import ProjectScreens from '../components/Work/ProjectScreens'

const Project = props => {
  const siteInfo = get(props, 'data.allContentfulGeneral.nodes[0]')
  const projects = get(props, 'data.allContentfulProject.nodes')
  const currentProject = get(props, 'data.contentfulProject')

  return (
    <Layout>
      <div>
        <Helmet
          {...{
            ...siteInfo,
            websiteTitle:
              siteInfo.websiteTitle +
              ' - Project ( ' +
              currentProject.title +
              ' )',
          }}
        />
        <div>
          <div>
            <Header showGrid logoSvg={siteInfo.logoSvg.logoSvg} />
            <Section>
              <br />
              <br />
              <br />
              <ScaleDown>
                <SectionTitle>work</SectionTitle>
              </ScaleDown>
            </Section>
            <FadeIn>
              <ProjectHeader>
                <Parallax strength={200}>
                  <ProjectHeaderContent color={currentProject.color}>
                    <ProjectItem>
                      <Img
                        fluid={currentProject.logo.fluid}
                        imgStyle={{
                          objectFit: 'contain',
                        }}
                      />
                    </ProjectItem>
                  </ProjectHeaderContent>
                </Parallax>
              </ProjectHeader>
            </FadeIn>
            <Section>
              <ProjectContent>
                <ProjectDescription
                  title={currentProject.title}
                  text={
                    currentProject.description.childMarkdownRemark
                      .rawMarkdownBody
                  }
                />
                <ProjectInvolvement
                  title="Involvement & Technologies"
                  tags={currentProject.tags}
                />
              </ProjectContent>
              <br />
              <br />
              <br />
              <ProjectContent>
                <ProjectScreens screens={currentProject.projectScreens} />
              </ProjectContent>
              <br />
              <br />
              <br />
              <ProjectsList
                projects={projects}
                excludeSlugs={[currentProject.slug]}
                title="Other Projects"
                shouldAllowExtra={false}
                compact
              />
              <Reveal fluid>
                <Cta to="/work" href>
                  See all projects
                </Cta>
              </Reveal>
            </Section>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Project

export const pageQuery = graphql`
  query($id: String) {
    allContentfulGeneral {
      nodes {
        ...general
      }
    }

    allContentfulProject {
      nodes {
        ...project
      }
    }

    contentfulProject(id: { eq: $id }) {
      ...project
    }
  }
`
